<template>
  <div class="dropdown-menu" v-if="isNotEmptySlot">
    <div class="dropdown-menu__dots" @click="toggleDropdownMenu" tabindex="-1" @blur="closeDropdownModal">
      <span></span>
    </div>

    <transition name="fade" v-if="!isMobile">
      <div class="dropdown-menu__items" :class="{ 'show-top': showItemsTop }" v-if="isOpen">
        <slot name="dropdownItems" />
        <slot />
      </div>
    </transition>

    <BaseModal
      v-else-if="dropdownIsModal === 'dropdown-settings'"
      hidden-close-btn
      class="modal-dropdown-settings"
      @close="closeModal"
    >
      <slot />
    </BaseModal>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'

export default {
  name: 'DropdownMenu',
  components: {
    BaseModal
  },
  props: {
    dropdownIsModal: {
      type: [Boolean, String],
      default: false
    },
    // Для вывода модалки вверх относительно кнопки
    showItemsTop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      isNotEmptySlot: true
    }
  },
  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    })
  },
  created () {
    this.isNotEmptySlot = !!this.$slots.default?.[0]
  },
  beforeUpdate () {
    this.isNotEmptySlot = !!this.$slots.default?.[0]
  },
  methods: {
    toggleDropdownMenu () {
      if (!this.isMobile) {
        this.isOpen = !this.isOpen
      } else {
        this.$emit('openModal', 'dropdown-settings')
      }
    },
    closeDropdownModal () {
      this.isOpen = false
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
