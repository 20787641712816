<template>
  <BaseModal class="modal-form" title="Бланк ТЗ" @close="onReject">
    <VForm class="d-flex flex-col ai-c" >
      <VButton
        text="Распечатать только для следующего этапа"
        type="submit"
        class="mb-8 btn--100"
        color="primary"
        size="xl"
        :loading="isLoadingBtnNext"
        @click.native="printOnlyNext"
      />
      <VButton
        text="Распечатать для следующего и обновить для текущего"
        type="submit"
        class="mb-20 btn--100"
        color="primary"
        size="xl"
        :loading="isLoadingBtnNextAndCurrent"
        @click.native="printOnlyAndCurrent"
      />
      <VButton
        text="Отмена"
        type="submit"
        class="m-center"
        size="l"
        @click.native="onReject"
      />
    </VForm>
  </BaseModal>
</template>

<script>
import { openPrint } from '@/utils/print/openPrint'
import VForm from '@/components/Form/VForm.vue'
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import VButton from '@/components/simple/button/VButton.vue'

export default {
  name: 'ModalConsignmentSplitPrint',
  components: {
    VButton,
    BaseModal,
    VForm
  },
  props: {
    consignmentId: {
      type: [String, Number],
      required: true
    },
    consignmentSplitId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      type: ''
    }
  },
  computed: {
    isLoadingBtnNext () {
      return this.type === 'only_next' && this.$store.state.isSendingRequestModal
    },
    isLoadingBtnNextAndCurrent () {
      return this.type === 'only_and_current' && this.$store.state.isSendingRequestModal
    }
  },
  methods: {
    onReject () {
      this.$emit('goSplitPrint')
    },
    async onRequest () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const params = {
          consignment_ids: this.getConsignmentIds(),
          page1: 'Y',
          page2: 'Y'
        }

        const response = await openPrint('technical-task', params)

        if (response?.status !== 200) return

        const data = this.getDataFile(response.data)
        this.$emit('request', data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    getDataFile (data) {
      return {
        url: data.url,
        name: data.url.split('/').at(-1)
      }
    },

    async printOnlyNext () {
      this.type = 'only_next'
      await this.onRequest()
    },

    async printOnlyAndCurrent () {
      this.type = 'only_and_current'
      await this.onRequest()
    },

    getConsignmentIds () {
      return this.type === 'only_next' ? [this.consignmentSplitId] : [this.consignmentId, this.consignmentSplitId]
    }
  }
}
</script>
